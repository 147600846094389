import React from "react";

export default function TCProcedureCarrying() {
  return (
    <div className="page-grid__content">
      <div className="text-area">
        <p>
          <b>Порядок технологического присоединения </b>
        </p>
        <b> </b>
        <p>
          <b>
            Технологическое присоединение до&nbsp;15&nbsp;кВт (для бытовых нужд){" "}
          </b>
        </p>
        <b> </b>
        <p>
          <b>1 ШАГ – ПОДАЧА ЗАЯВКИ</b>
        </p>
        <p>
          Подать заявку на ТП можно самостоятельно или с помощью своего
          представителя по доверенности:
        </p>
        <ul>
          <li>
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://moetp.ru/desktop/"
              >
                В личном кабинете на Портале
              </a>
            </p>
          </li>
        </ul>
        <p>
          <b>К заявке необходимо приложить документы:</b>
        </p>
        <ul>
          <li>
            <p>
              план расположения энергопринимающих устройств (можно начерченный
              от руки), которые необходимо присоединить, с привязкой к
              ориентирам;
            </p>
          </li>
          <li>
            <p>
              копия документа, подтверждающего право собственности или иное
              предусмотренное законом основание на объект капитального
              строительства и /или земельный участок, на котором
              расположены/будут располагаться объекты заявителя, либо право
              собственности или иное предусмотренное законом основание на
              энергопринимающее устройство;
            </p>
          </li>
          <li>
            <p>
              доверенность или иные документы, подтверждающие полномочия
              представителя заявителя, подающего заявку в сетевую организацию;
            </p>
          </li>
          <li>
            <p>
              копия паспорта гражданина Российской Федерации или иного
              документа, удостоверяющего личность, если заявителем выступает
              индивидуальный предприниматель или гражданин;
            </p>
          </li>
          <li>
            <p>
              копия документа, подтверждающего согласие организации,
              осуществляющей управление многоквартирным домом либо согласие
              общего собрания владельцев жилых помещений многоквартирного дома
              на организацию присоединения (для объектов заявителей,
              расположенных в нежилом помещении многоквартирного дома).
            </p>
          </li>
        </ul>
        <p>
          <b>2 ШАГ – ЗАКЛЮЧЕНИЕ ДОГОВОРА</b>
        </p>
        <p>
          <b>Для заключения договора заявителю необходимо:</b>
        </p>
        <p>
          Сетевая организация направляет заявителю в Личный кабинет в течение 10
          рабочих дней со дня получения заявки:
        </p>
        <ul>
          <li>
            <p>условия типового договора технологического присоединения;</p>
          </li>
          <li>
            <p>
              инструкция с перечнем мероприятий для самостоятельного и
              безопасного осуществления фактического присоединения будут
              размещены в Личном кабинете по умолчанию;
            </p>
          </li>
          <li>
            <p>технические условия;</p>
          </li>
          <li>
            <p>
              счет для оплаты оказываемых услуг (части платы) и сопроводительное
              письмо - после подписания усиленной квалифицированной электронной
              подписью уполномоченного лица сетевой организации.
            </p>
          </li>
        </ul>
        <p>
          <b>Заявителю необходимо:</b>
        </p>
        <ul>
          <li>
            <p>ознакомиться с полученными документами в Личном кабинете;</p>
          </li>
          <li>
            <p>
              произвести оплату удаленно банковской картой в{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://moetp.ru/desktop/personal-applications/"
              >
                Личном кабинете Заявителя
              </a>
              *&nbsp;
            </p>
          </li>
        </ul>
        <p>
          Для заключения договора технологического присоединения в Личном
          кабинете необходимо нажать «Оплатить» и следовать дальнейшим
          инструкциям. Дата оплаты выставленного счета – дата вступления
          договора в силу.
        </p>
        <p>
          *Обращаем внимание! Срок действия выставленного счета для заключения
          договора технологического присоединения в Личном кабинете составляет 5
          рабочих дней со дня размещения, в случае неоплаты в указанный срок,
          заявка будет аннулирована.
        </p>
        <p>
          <b>3 ШАГ – Выполнение работ</b>
        </p>
        <p>
          На этом этапе необходимо выполнить работы, предусмотренные
          Техническими условиями. Присоединение первичное? Тогда сетевая
          организация самостоятельно монтирует и вводит в эксплуатацию прибор
          учета.
        </p>
        <p>
          Дальнейшие мероприятия зависят от уровня напряжения в соответствии с
          поданной заявкой на технологическое присоединение*
        </p>
        <p>
          В результате выполнения технических условий сетевая организация
          направит в Личный кабинет акты, подтверждающие технологическое
          присоединение. Подписывать акты не требуется – процедура присоединения
          со стороны сетевой организации завершена.
        </p>
        <p>
          Заявителю необходимо самостоятельно подключить свои энергопринимающие
          устройства к созданной точке присоединения, руководствуясь размещенной
          ранее в Личном кабинете инструкцией с перечнем мероприятий для
          самостоятельного и безопасного осуществления фактического
          присоединения.
        </p>
        <p>
          *Более подробно ознакомиться с порядком уведомления заявителей о
          выполнении технических условий можно самостоятельно (п. 85 Правил
          технологического присоединения –&nbsp;
          <a href="http://www.consultant.ru/document/cons_doc_LAW_51030/">
            Постановление Правительства РФ № 861 от27.12.2004
          </a>
        </p>
        <p>
          <b>
            Технологическое присоединение
            свыше&nbsp;15&nbsp;до&nbsp;150&nbsp;кВт (для малого и среднего
            бизнеса){" "}
          </b>
        </p>
        <b> </b>
        <p>
          <b>1 ШАГ – ПОДАЧА ЗАЯВКИ</b>
        </p>
        <p>
          Подать заявку на ТП можно самостоятельно или с помощью своего
          представителя по доверенности:
        </p>
        <ul>
          <li>
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://moetp.ru/desktop/"
              >
                В личном кабинете на Портале
              </a>
              &nbsp;
            </p>
          </li>
        </ul>
        <p>
          <b>К заявке необходимо приложить документы:</b>
        </p>
        <ul>
          <li>
            <p>
              план расположения энергопринимающих устройств (можно начерченный
              от руки), которые необходимо присоединить, с привязкой к
              ориентирам;
            </p>
          </li>
          <li>
            <p>
              копия документа, подтверждающего право собственности или иное
              предусмотренное законом основание на объект капитального
              строительства и /или земельный участок, на котором
              расположены/будут располагаться объекты заявителя, либо право
              собственности или иное предусмотренное законом основание на
              энергопринимающее устройство;
            </p>
          </li>
          <li>
            <p>
              доверенность или иные документы, подтверждающие полномочия
              представителя заявителя, подающего заявку в сетевую организацию;
            </p>
          </li>
          <li>
            <p>
              для юридических лиц - выписка из Единого государственного реестра
              юридических лиц, для индивидуальных предпринимателей - выписка из
              Единого государственного реестра индивидуальных предпринимателей;
            </p>
          </li>
          <li>
            <p>
              копия документа, подтверждающего согласие организации,
              осуществляющей управление многоквартирным домом либо согласие
              общего собрания владельцев жилых помещений многоквартирного дома
              на организацию присоединения (для объектов заявителей,
              расположенных в нежилом помещении многоквартирного дома).
            </p>
          </li>
        </ul>
        <p>
          <b>Для заключения договора заявителю необходимо:</b>
        </p>
        <p>
          Сетевая организация направляет заявителю в Личный кабинет в течение 10
          рабочих дней со дня получения заявки:
        </p>
        <ul>
          <li>
            <p>условия типового договора технологического присоединения;</p>
          </li>
          <li>
            <p>
              инструкция с перечнем мероприятий для самостоятельного и
              безопасного осуществления фактического присоединения будут
              размещены в Личном кабинете по умолчанию;
            </p>
          </li>
          <li>
            <p>технические условия;</p>
          </li>
          <li>
            <p>
              счет для оплаты оказываемых услуг (части платы) и сопроводительное
              письмо - после подписания усиленной квалифицированной электронной
              подписью уполномоченного лица сетевой организации.
            </p>
          </li>
        </ul>
        <p>
          <b>Заявителю необходимо:</b>
        </p>
        <ul>
          <li>
            <p>ознакомиться с полученными документами в Личном кабинете;</p>
          </li>
          <li>
            <p>
              произвести оплату удаленно банковской картой в{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://moetp.ru/desktop/personal-applications/"
              >
                Личном кабинете Заявителя
              </a>
              *
            </p>
          </li>
        </ul>
        <p>
          Для заключения договора технологического присоединения в Личном
          кабинете необходимо нажать «Оплатить» и следовать дальнейшим
          инструкциям. Дата оплаты выставленного счета – дата вступления
          договора в силу.
        </p>
        <p>
          <i>
            *Обращаем внимание! Срок действия выставленного счета для заключения
            договора технологического присоединения в Личном кабинете составляет
            5 рабочих дней со дня размещения, в случае неоплаты в указанный
            срок, заявка будет аннулирована.
          </i>
        </p>
        <p>
          <b>3 ШАГ – Выполнение работ</b>
        </p>
        <p>
          На этом этапе необходимо выполнить работы, предусмотренные
          Техническими условиями. Присоединение первичное? Тогда сетевая
          организация самостоятельно монтирует и вводит в эксплуатацию прибор
          учета.
        </p>
        <p>
          Дальнейшие мероприятия зависят от уровня напряжения в соответствии с
          поданной заявкой на технологическое присоединение*
        </p>
        <p>
          В результате выполнения технических условий сетевая организация
          направит в Личный кабинет акты, подтверждающие технологическое
          присоединение. Подписывать акты не требуется – процедура присоединения
          со стороны сетевой организации завершена. Заявителю необходимо
          самостоятельно подключить свои энергопринимающие устройства к
          созданной точке присоединения, руководствуясь размещенной ранее в
          Личном кабинете инструкцией с перечнем мероприятий для
          самостоятельного и безопасного осуществления фактического
          присоединения.
        </p>
        <p>
          <i>
            *Более подробно ознакомиться с порядком уведомления заявителей о
            выполнении технических условий можно самостоятельно (п. 85 Правил
            технологического присоединения –&nbsp;
          </i>
          <a href="http://www.consultant.ru/document/cons_doc_LAW_51030/">
            <i>Постановление Правительства РФ № 861 от27.12.2004</i>
          </a>
        </p>
        <p>
          <b>
            Технологическое присоединение от&nbsp;150&nbsp;кВт
            до&nbsp;670&nbsp;кВт
          </b>
        </p>
        <p>
          <b>1 ШАГ – ПОДАЧА ЗАЯВКИ</b>
        </p>
        <p>
          Подать заявку на ТП можно самостоятельно или с помощью своего
          представителя по доверенности:
        </p>
        <ul>
          <li>
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://moetp.ru/desktop/"
              >
                В личном кабинете на Портале
              </a>
            </p>
          </li>
          <li>
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://moetp.ru/kontakti/"
              >
                В центрах обслуживания клиентов&nbsp;
              </a>
            </p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://moetp.ru/kontakti/"
            >
              {" "}
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://moetp.ru/kontakti/"
            >
              {" "}
            </a>
            направить Почтой России.
          </li>
        </ul>
        <p>
          <b>К заявке необходимо приложить документы:</b>
        </p>
        <p></p>
        <ol>
          <li>
            <p>план расположения энергопринимающего устройства (План ЭПУ);</p>
          </li>
          <li>
            <p>
              копия документа, подтверждающего право собственности или иное
              предусмотренное законом основание на объект капитального
              строительства и (или) земельный участок, на котором расположены
              (будут располагаться) объекты заявителя, либо право собственности
              или иное предусмотренное законом основание на ЭПУ;
            </p>
          </li>
          <li>
            <p>
              для ЮЛ - выписка из ЕГРЮЛ, для ИП - выписка из ЕГРИП, для ФЛ и ИП
              – копия паспорта гражданина Российской Федерации или иного
              документа, удостоверяющего личность;
            </p>
          </li>
          <li>
            <p>
              доверенность или иные документы, подтверждающие полномочия
              представителя заявителя, подающего или получающего документы,
              случае если заявка подается в АО «Мособлэнерго» представителем
              заявителя;
            </p>
          </li>
          <li>
            <p>
              в случае присоединения ЭПУ, находящихся в нежилых помещениях,
              расположенных в многоквартирных домах – копия документа,
              подтверждающего согласие организации, осуществляющей управление
              многоквартирным домом либо согласие общего собрания владельцев
              жилых помещений многоквартирного дома на организацию
              присоединения;
            </p>
          </li>
          <li>
            <p>
              в случае присоединения ЭПУ, принадлежащих СНТ/ДНП - справка о
              количестве земельных участков, расположенных на территории, с
              указанием информации о фамилии, имени, отчестве владельцев
              земельных участков, сериях, номерах и датах выдачи паспортов или
              иных документов, удостоверяющих личность, а также в случае наличия
              – кадастровые номера земельных участков и данные о величине
              максимальной мощности, выделенной на каждый земельный участок.
            </p>
          </li>
        </ol>
        <p>
          <b>
            Сетевая организация в течение 20 рабочих дней подготавливает проект
            договора ТП и технические условия (ТУ) и направляет заявителю:
          </b>
        </p>
        <p>
          <b>Для заключения договора заявителю необходимо:</b>
        </p>
        <ul>
          <li>
            <p>ознакомиться с полученным Договором ТП;</p>
          </li>
          <li>
            <p>
              устранить все возникшие вопросы, связавшись удобном способом с
              специалистами сетевой организации;
            </p>
          </li>
          <li>
            <p>
              в течение 10 рабочих дней с момента получения подписать Договор
              ТП.
            </p>
          </li>
        </ul>
        <p>
          <i>
            *Обращаем внимание! В случае не направления Заявителем подписанного
            Договора ТП в сетевую организацию, но не ранее чем через 30 рабочих
            дней со дня получения оферты Договора ТП, поданная заявка
            аннулируется.
          </i>
        </p>
        <p>
          <b>3 ШАГ – Выполнение работ</b>
        </p>
        <p>
          <b>На этом этапе необходимо:</b>
        </p>
        <ul>
          <li>
            <p>
              выполнить работы, предписанные Техническими условиями и уведомить
              об этом сетевую организацию;
            </p>
          </li>
        </ul>
        <p>
          Сетевая организация выполняет работы до границ земельного участка
          Заявителя, на котором расположены ЭПУ Заявителя.
        </p>
        <p>
          Заявитель выполняет мероприятия в границах своего земельного участка,
          на котором расположены ЭПУ Заявителя, включая установку узла учета на
          границе разграничения балансовой принадлежности.
        </p>
        <p>
          <b>Уведомить о выполнении ТУ можно любым из способов:</b>
        </p>
        <ul>
          <li>
            <p>
              при очном визите в&nbsp;
              <a href="https://moetp.ru/kontakti/">один из клиентских офисов</a>
              ;
            </p>
          </li>
          <li>
            <p>
              письменно, направив уведомление в адрес подразделения организации,
              с которым был заключен договор ТП;
            </p>
          </li>
          <li>
            <p>
              через Личный кабинет заявителя в случае подачи электронной заявки.
            </p>
          </li>
        </ul>
        <p>
          В течение 10 дней специалисты сетевой организации осуществят
          согласованный с заявителем выезд на осмотр выполнения Технических
          условий.
        </p>
        <p>
          После осмотра (обследования) ЭПУ при отсутствии несоответствия
          выполнения ТУ подача напряжения на объект производится специалистами
          организации.
        </p>
        <p>
          Если при проверке выполнения ТУ выявлены несоответствия, сетевая
          организация предоставит информацию о выявленных нарушениях и
          рекомендации по их устранению.
        </p>
        <p>
          <i>
            *Более подробно ознакомиться с порядком уведомления заявителей о
            выполнении технических условий можно самостоятельно (п. 85 Правил
            технологического присоединения –&nbsp;
          </i>
          <a href="http://www.consultant.ru/document/cons_doc_LAW_51030/">
            <i>Постановление Правительства РФ № 861 от27.12.2004</i>
          </a>
        </p>
        <p>
          <b>4 ШАГ – Получение актов</b>
        </p>
        <p>
          Все документы, сопутствующие процессу технологического присоединения
          (акт о технологическом присоединении) будут направлены:
        </p>
        <ul>
          <li>
            <p>
              в Личный кабинет (при выборе заявителем способа подписания
              документов с помощью ЭЦП);
            </p>
          </li>
          <li>
            <p>
              в остальных случаях, Заявитель сможет забрать готовые
              документы&nbsp;<a href="https://moetp.ru/kontakti/">в&nbsp;</a>
              <a href="http://mrsk-cp.ru/for_consumers/consumer_service/consumer_service_office/">
                Центрах
              </a>
              <a href="https://moetp.ru/kontakti/">
                &nbsp;обслуживания клиентов
              </a>
              .
            </p>
          </li>
        </ul>
        <p>
          <i>
            *ИНФОРМАЦИЯ для Заявителей! Введение объекта до 670 кВт по III
            категории надежности в эксплуатацию не требует участия органа
            федерального государственного энергетического надзора и носит
            уведомительный характер.
          </i>
        </p>
        <p>
          <b>Технологическое присоединение свыше&nbsp;670&nbsp;кВт</b>
        </p>
        <p>
          <b>1 ШАГ – ПОДАЧА ЗАЯВКИ</b>
        </p>
        <p>
          <b>
            Подать заявку на ТП можно самостоятельно или с помощью своего
            представителя по доверенности:
          </b>
        </p>
        <ul>
          <li>
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://moetp.ru/desktop/"
              >
                В личном кабинете на Портале
              </a>
            </p>
          </li>
          <li>
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://moetp.ru/kontakti/"
              >
                В центрах обслуживания клиентов
              </a>
              &nbsp;
            </p>
          </li>
          <li>
            <p>направить Почтой России.</p>
          </li>
        </ul>
        <p>
          <b>К заявке необходимо приложить документы:</b>
        </p>
        <p></p>
        <ol>
          <li>
            <p>план расположения энергопринимающего устройства (План ЭПУ);</p>
          </li>
          <li>
            <p>
              копия документа, подтверждающего право собственности или иное
              предусмотренное законом основание на объект капитального
              строительства и (или) земельный участок, на котором расположены
              (будут располагаться) объекты заявителя, либо право собственности
              или иное предусмотренное законом основание на ЭПУ;
            </p>
          </li>
          <li>
            <p>
              для ЮЛ - выписка из ЕГРЮЛ, для ИП - выписка из ЕГРИП, для ФЛ и ИП
              – копия паспорта гражданина Российской Федерации или иного
              документа, удостоверяющего личность;
            </p>
          </li>
          <li>
            <p>
              доверенность или иные документы, подтверждающие полномочия
              представителя заявителя, подающего или получающего документы,
              случае если заявка подается в АО «Мособлэнерго» представителем
              заявителя;
            </p>
          </li>
          <li>
            <p>
              в случае присоединения ЭПУ, находящихся в нежилых помещениях,
              расположенных в многоквартирных домах – копия документа,
              подтверждающего согласие организации, осуществляющей управление
              многоквартирным домом либо согласие общего собрания владельцев
              жилых помещений многоквартирного дома на организацию
              присоединения;
            </p>
          </li>
          <li>
            <p>
              в случае присоединения ЭПУ, принадлежащих СНТ/ДНП - справка о
              количестве земельных участков, расположенных на территории, с
              указанием информации о фамилии, имени, отчестве владельцев
              земельных участков, сериях, номерах и датах выдачи паспортов или
              иных документов, удостоверяющих личность, а также в случае наличия
              – кадастровые номера земельных участков и данные о величине
              максимальной мощности, выделенной на каждый земельный участок.
            </p>
          </li>
        </ol>
        <p></p>
        <p>
          <b>
            *ВНИМАНИЕ! ИНФОРМАЦИЯ ДЛЯ ЗАЯВИТЕЛЕЙ! Срок рассмотрения заявки может
            быть увеличен в связи со сложным характером ТП:
          </b>
        </p>
        <ul>
          <li>
            <p>на срок согласования ТУ с вышестоящей сетевой организацией;</p>
          </li>
          <li>
            <p>
              на срок утверждения платы за ТП по индивидуальному проекту,
              установленному уполномоченным органом исполнительной власти в
              области государственного регулирования тарифов.
            </p>
          </li>
        </ul>
        <p>
          <b>
            Сетевая организация в течение 20 рабочих дней подготавливает проект
            договора ТП и технические условия (ТУ) и направляет заявителю:
          </b>
        </p>
        <p>
          <b>Для заключения договора заявителю необходимо:</b>
        </p>
        <ul>
          <li>
            <p>ознакомиться с полученным Договором ТП;</p>
          </li>
          <li>
            <p>
              устранить все возникшие вопросы, связавшись удобном способом с
              специалистами сетевой организации;
            </p>
          </li>
          <li>
            <p>
              в течение 10 рабочих дней с момента получения подписать Договор
              ТП.
            </p>
          </li>
        </ul>
        <p>
          <i>
            *Обращаем внимание! В случае не направления Заявителем подписанного
            Договора ТП в сетевую организацию, но не ранее чем через 30 рабочих
            дней со дня получения оферты Договора ТП, поданная заявка
            аннулируется.
          </i>
        </p>
        <p>
          <b>3 ШАГ – Выполнение работ</b>
        </p>
        <p>
          <b>На этом этапе необходимо:</b>
        </p>
        <ul>
          <li>
            <p>
              выполнить работы, предписанные Техническими условиями и уведомить
              об этом сетевую организацию;
            </p>
          </li>
        </ul>
        <p>
          Сетевая организация выполняет работы до границ земельного участка
          Заявителя, на котором расположены ЭПУ Заявителя. Срок осуществления
          мероприятий по ТП составляет от 1 года в зависимости от выполняемых
          работ. Срок выполнения мероприятий будет указан в проекте договора ТП.
        </p>
        <p>
          Заявитель выполняет мероприятия в границах своего земельного участка,
          на котором расположены ЭПУ Заявителя, включая установку узла учета на
          границе разграничения балансовой принадлежности.
        </p>
        <p>
          <b>Уведомить о выполнении ТУ можно любым из способов:</b>
        </p>
        <ul>
          <li>
            <p>
              При очном визите в&nbsp;
              <a href="https://moetp.ru/kontakti/">один из клиентских офисов</a>
              ;
            </p>
          </li>
          <li>
            <p>
              Письменно, направив уведомление в адрес подразделения организации,
              с которым был заключен договор ТП;
            </p>
          </li>
          <li>
            <p>
              Через Личный кабинет заявителя в случае подачи электронной заявки.
            </p>
          </li>
        </ul>
        <p>
          <b>
            Для проведения проверки выполнения технических условий заявитель
            представляет уведомление о выполнении технических условий с
            приложением следующих документов:
          </b>
        </p>
        <p></p>
        <ul>
          <li>
            <p>
              копии сертификатов соответствия на электрооборудование, если
              оборудование подлежит обязательной сертификации и (или)
              сопроводительной технической документации (технические паспорта
              оборудования), содержащей сведения о сертификации;
            </p>
          </li>
          <li>
            <p>
              копии разделов проектной документации, предусматривающих
              технические решения, обеспечивающие выполнение технических
              условий, в том числе решения по схеме внешнего электроснабжения
              (схеме выдачи мощности объектов по производству электрической
              энергии), релейной защите и автоматике, телемеханике и связи, в
              случае если такая проектная документация не была представлена
              заявителем в сетевую организацию до направления заявителем в
              сетевую организацию уведомления о выполнении технических условий,
              если в соответствии с законодательством Российской Федерации о
              градостроительной деятельности разработка проектной документации
              является обязательной;
            </p>
          </li>
          <li>
            <p>
              документы, содержащие информацию о результатах проведения
              пусконаладочных работ, приемо-сдаточных и иных испытаний; (не
              требуется для заявителей до 1000 В, осветительные установки,
              переносное электрооборудование и энергопринимающие устройства
              номинальным напряжением не выше 380 В);
            </p>
          </li>
          <li>
            <p>
              нормальные (временные нормальные) схемы электрических соединений
              объекта электроэнергетики, в том числе однолинейная схема
              электрических соединений (электроустановки). (не требуется для
              заявителей до 1000 В, осветительные установки, переносное
              электрооборудование и энергопринимающие&nbsp;устройства
              номинальным напряжением не выше 380 В).
            </p>
          </li>
        </ul>
        <p></p>
        <p>
          <i>
            *Более подробно ознакомиться с порядком уведомления заявителей о
            выполнении технических условий можно самостоятельно (п. 85 Правил
            технологического присоединения –&nbsp;
          </i>
          <a href="http://www.consultant.ru/document/cons_doc_LAW_51030/">
            <i>Постановление Правительства РФ № 861 от27.12.2004</i>
          </a>
        </p>
        <p>
          <b>4 ШАГ – Подача напряжения</b>
        </p>
        <p>
          <b>На этом этапе Вам необходимо:</b>
        </p>
        <ul>
          <li>
            <p>уведомить сетевую организацию о выполнении ТУ;</p>
          </li>
          <li>
            <p>
              принять участие совместно с сетевой организацией в осмотре ЭПУ;
            </p>
          </li>
          <li>
            <p>
              если при проверке выполнения Заявителем ТУ выявлены
              несоответствия, сетевая организация предоставит информацию о
              выявленных нарушениях и рекомендации по их устранению.
            </p>
          </li>
        </ul>
        <p>
          <b>
            Если все работы со стороны заявителя проведены в соответствии с ТУ:{" "}
          </b>
        </p>
        <b> </b>
        <ul>
          <li>
            <b> </b>
            <p>получить Акт о выполнении ТУ;</p>
          </li>
          <li>
            <p>
              получить Акт уполномоченного органа федерального государственного
              энергетического надзора на допуск в эксплуатацию объектов
              заявителя;
            </p>
          </li>
          <li>
            <p>
              Сетевая организация осуществит фактическую подачу электроэнергии
              на ЭПУ Заявителя путем включения коммутационного аппарата.
            </p>
          </li>
        </ul>
        <p>
          <b>5 ШАГ – Получение актов</b>
        </p>
        <p>
          После оформления Акт выполнения технических условий ЭПУ Заявителя
          сетевая организация подготовит и направит в Личный кабинет или для
          получения очно{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://moetp.ru/kontakti/"
          >
            в&nbsp;
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://moetp.ru/kontakti/"
          >
            центрах обслуживания клиентов
          </a>
          :
        </p>
        <ul>
          <li>
            <p>акт об осуществлении технологического присоединения.</p>
          </li>
        </ul>
        <p>Подписанные Акты необходимо возвратить в сетевую организацию.</p>
      </div>
    </div>
  );
}
