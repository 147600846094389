import React from "react";

export default function TCMainInformation() {
  return (
    <div className="page-grid__content" id="content">
      <div className="text-area">
        <h3>Технологическое присоединение </h3>
        <p>
          Технологическое присоединение – это услуга, оказываемая сетевой
          организацией для подключения энергопринимающих устройств юридических,
          физических лиц и индивидуальных предпринимателей к электрическим
          сетям.
        </p>
        <p>
          Порядок технологического присоединения энергопринимающих устройств
          определен Правилами технологического присоединения энергопринимающих
          устройств потребителей электрической энергии, объектов по производству
          электрической энергии, а также объектов электросетевого хозяйства,
          принадлежащих сетевым организациям и иным лицам, к электрическим
          сетям, утвержденных постановлением Правительства Российской Федерации
          от 27.12.2004 г. № 861 (далее – Правила), а также Федеральным законом
          от 26.03.2003г. №35-ФЗ «Об электроэнергетике», и иными документами,
          размещенным в подразделе «Нормативные документы».
        </p>
        <p>
          <b>Процедура технологического присоединения выполняется в случае:</b>
        </p>
        <ul>
          <li>
            <p>
              присоединения впервые вводимых в эксплуатацию энергопринимающих
              устройств;
            </p>
          </li>
          <li>
            <p>
              увеличения максимальной мощности ранее присоединенных
              реконструируемых энергопринимающих устройств;
            </p>
          </li>
          <li>
            <p>
              изменения категории надежности электроснабжения энергопринимающих
              устройств;
            </p>
          </li>
          <li>
            <p>изменения точек присоединения к сети;</p>
          </li>
          <li>
            <p>
              изменения вида производственной деятельности, не влекущего
              пересмотр величины максимальной мощности, но изменяющего схему
              внешнего электроснабжения энергопринимающих устройств.
            </p>
          </li>
        </ul>
        <p>
          Технологическое присоединение осуществляется на возмездной основе, на
          основании договора, заключаемого между сетевой организацией и
          заявителем.
        </p>
        <p>
          <b>
            Срок осуществления мероприятий по технологическому присоединению:
          </b>
        </p>
        <p>
          В случаях осуществления технологического присоединения к электрическим
          сетям классом напряжения до 20 кВ включительно, при этом расстояние от
          существующих электрических сетей необходимого класса напряжения до
          границ участка, на котором расположены присоединяемые
          энергопринимающие устройства, составляет не более 300 метров в городах
          и поселках городского типа и не более 500 метров в сельской местности
          и от сетевой организации не требуется выполнение работ по
          строительству (реконструкции) объектов электросетевого хозяйства,
          включенных (подлежащих включению) в инвестиционные программы сетевых
          организаций (в том числе смежных сетевых организаций), и (или)
          объектов по производству электрической энергии, за исключением работ
          по строительству объектов электросетевого хозяйства от существующих
          объектов электросетевого хозяйства до присоединяемых энергопринимающих
          устройств и (или) объектов электроэнергетики:
        </p>
        <p></p>
        <ul>
          <li>
            15 рабочих дней (если в заявке не указан более продолжительный срок)
            для осуществления мероприятий по технологическому присоединению,
            отнесенных к обязанностям сетевой организации, - при временном
            технологическом присоединении;
          </li>
          <li>
            4 месяца - для заявителей, максимальная мощность энергопринимающих
            устройств которых составляет до 670 кВт включительно;
          </li>
          <li>
            1 год - для заявителей, максимальная мощность энергопринимающих
            устройств которых составляет свыше 670 кВт.
          </li>
        </ul>
        <p>
          <b>В иных случаях:</b>
        </p>
        <p></p>
        <ul>
          <li>
            15 рабочих дней (если в заявке не указан более продолжительный срок)
            - при временном технологическом присоединении заявителей,
            энергопринимающие устройства которых являются передвижными и имеют
            максимальную мощность до 150 кВт включительно, если расстояние от
            энергопринимающего устройства заявителя до существующих
            электрических сетей необходимого класса напряжения составляет не
            более 300 метров;
          </li>
          <li>
            30 рабочих дней - для заявителей, указанных в пунктах 12(1) и 14
            Правил, при одновременном соблюдении следующих условий:
          </li>
        </ul>
        <ol>
          <li>
            технологическое присоединение энергопринимающих устройств заявителя
            осуществляется к электрическим сетям классом напряжения 0,4 кВ и
            ниже;
          </li>
          <li>
            расстояние от существующих электрических сетей необходимого класса
            напряжения до границ участка, на котором расположены присоединяемые
            энергопринимающие устройства, составляет не более 15 метров;
          </li>
          <li>
            отсутствует необходимость урегулирования отношений с лицами,
            являющимися собственниками или иными законными владельцами земельных
            участков, расположенных полностью или частично между ближайшим
            объектом электрической сети, имеющим указанный в заявке класс
            напряжения и используемым сетевой организацией для осуществления
            технологического присоединения энергопринимающих устройств
            заявителя, и земельным участком заявителя;
          </li>
          <li>
            от сетевой организации не требуется выполнение работ по
            строительству (реконструкции) объектов электросетевого хозяйства,
            включенных (подлежащих включению) в инвестиционные программы сетевых
            организаций (в том числе смежных сетевых организаций), и (или)
            объектов по производству электрической энергии, за исключением работ
            по строительству объектов электросетевого хозяйства от существующих
            объектов электросетевого хозяйства до присоединяемых
            энергопринимающих устройств, а также по обеспечению коммерческого
            учета электрической энергии (мощности);
          </li>
        </ol>
        <p></p>
        <p align="justify"></p>
        <ul>
          <li>
            6 месяцев - для заявителей, указанных в пунктах 12(1), 14 и 34
            Правил, если технологическое присоединение осуществляется к
            электрическим сетям, уровень напряжения которых составляет до 20 кВ
            включительно, и если расстояние от существующих электрических сетей
            необходимого класса напряжения до границ участка заявителя, на
            котором расположены присоединяемые энергопринимающие устройства,
            составляет не более 300 метров в городах и поселках городского типа
            и не более 500 метров в сельской местности;
          </li>
          <li>
            1 год - для заявителей, максимальная мощность энергопринимающих
            устройств которых составляет менее 670 кВт, а также для заявителей,
            максимальная мощность энергопринимающих устройств которых составляет
            не менее 670 кВт, при технологическом присоединении к объектам
            электросетевого хозяйства организации по управлению единой
            национальной (общероссийской) электрической сетью;
          </li>
          <li>
            2 года - для заявителей, максимальная мощность энергопринимающих
            устройств которых составляет не менее 670 кВт, в том числе при
            технологическом присоединении к объектам электросетевого хозяйства
            организации по управлению единой национальной (общероссийской)
            электрической сетью, если для осуществления технологического
            присоединения энергопринимающих устройств или объектов
            электроэнергетики заявителя требуется выполнение работ по
            строительству (реконструкции) объектов электросетевого хозяйства,
            включенных (подлежащих включению) в инвестиционные программы смежных
            сетевых организаций, и (или) объектов по производству электрической
            энергии;
          </li>
          <li>
            Для заявителей, максимальная мощность энергопринимающих устройств
            которых составляет не менее 670 кВт, по инициативе (обращению)
            заявителя договором могут быть установлены иные сроки (но не более 4
            лет). В случае заключения сетевой организацией договора со смежной
            сетевой организацией в соответствии с требованиями пункта 41 Правил
            технологического присоединения срок осуществления мероприятий по
            технологическому присоединению определяется в порядке, установленном
            настоящими Правилами.
          </li>
        </ul>
      </div>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://moetp.ru/desktop/"
      >
        <p style={{ textAlign: "center" }}>ПОДАТЬ ЗАЯВКУ</p>
      </a>
      <br />{" "}
    </div>
  );
}
