import React from "react";
import { addressServer } from "../config";
import pdf from "../img/pdf.svg";
import { motion } from "framer-motion";
import TopImage from "../components/TopImage";
import img37550ba6c53ac1236dc634e6c4f22cc1 from "../img/37550ba6c53ac1236dc634e6c4f22cc1.jpg";
const mainDirection = [
  "Оказание услуг по передаче электроэнергии;",
  "Технологическое присоединение энергопринимающих устройств (энергетических установок) юридических и физических лиц к электрическим сетям Общества;",
  "Обеспечение надежности и бесперебойности энергоснабжения, обеспечение энергобезопасности;",
  "Повышение качества обслуживания, обеспечение удовлетворенности потребителей;",
  "Обеспечение доступности инфраструктуры и создание условий для экономического роста;",
  "Повышение эффективности функционирования электросетевого комплекса;",
  "Реализация программы консолидации электросетевых активов на территории Московской области (в том числе бесхозяйных сетей);",
  "Повышение уровня корпоративного управления;",
  "Обеспечение рациональной организации труда производственного персонала, повышение его лояльности;",
  "Снижение рисков травматизма персонала и сторонних лиц на объектах электросетевого комплекса.",
];
export default function About() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <TopImage
        image={img37550ba6c53ac1236dc634e6c4f22cc1}
        title={"Информация о компании"}
      />
      <div className="page-grid__content" id="content">
        <div className="text-area border-bottom">
          <p>
            Акционерное общество «Московская областная энергосетевая компания»
            создано на основании постановления Правительства Московской области
            от 19.07.2005 № 456/26 «Об участии Московской области в создании
            открытого акционерного общества «Московская областная энергосетевая
            компания». АО «Мособлэнерго» учреждено в соответствии с Федеральным
            законом «Об акционерных обществах», Гражданским кодексом Российской
            Федерации и иными нормативными правовыми актами Российской
            Федерации, регулирующими деятельность хозяйственных обществ и
            зарегистрировано в качестве юридического лица 07 ноября 2005 года.
          </p>
          <p>
            Создание АО «Мособлэнерго» в 2005 году со 100-процентным участием в
            уставном капитале Московской области было вызвано необходимостью
            решить накопившиеся проблемы в региональном электроснабжении, такие
            как дефицит электрической мощности, снижение надежности
            электроснабжения потребителей, высокий уровень потерь при передаче
            электрической энергии.
          </p>
          <p>
            Сегодня АО «Мособлэнерго» является одной из крупнейших энергосетевых
            компаний региона, которая обеспечивает потребности экономики в
            передающих мощностях, является надежным партнером государственных
            органов исполнительной власти Московской области и органов местного
            самоуправления в планировании и реализации программ территориального
            развития Московской области, добросовестным налогоплательщиком и
            работодателем.
          </p>
          <p className="text-md">Основные направления деятельности:</p>
          <ul>
            {mainDirection.map((item, index) => (
              <li key={index}>
                <p style={{ display: "flex", alignItems: "center" }}>
                  {/* <img src={checkIcon} style={{ marginRight: "10px" }} /> */}
                  {item}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <br />{" "}
        <div className="row-docs-age">
          <a
            className="doc-line"
            href={`${addressServer}/uploads/politika_f5e5f82c29.pdf?updated_at=2024-06-14T18:45:03.258Z`}
            download=""
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="doc-line__wrap-icon">
              <img src={pdf} alt="icon pdf" />
            </div>
            <div className="doc-line__wrap-text">
              <span className="doc-line__name">
                {" "}
                ПОЛИТИКА ОБРАБОТКИ И ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ АО
                "МОСОБЛЭНЕРГО"
              </span>
              <span className="doc-line__file-info">pdf, 1МБ</span>
            </div>
          </a>

          <a
            className="doc-line"
            href={`${addressServer}/uploads/da431a6abcbd0b2e80fb8669efc7b934_compressed_915b6278fc.pdf?updated_at=2022-10-28T09:11:34.786Z`}
            download=""
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="doc-line__wrap-icon">
              <img src={pdf} alt="icon pdf" />
            </div>
            <div className="doc-line__wrap-text">
              <span className="doc-line__name">
                Политика в области качества
              </span>
              <span className="doc-line__file-info">pdf, 1МБ</span>
            </div>
          </a>
        </div>
      </div>
    </motion.div>
  );
}
