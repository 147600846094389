import React from "react";
import { motion } from "framer-motion";
import TopImage from "../components/TopImage";
import img37550ba6c53ac1236dc634e6c4f22cc1 from "../img/37550ba6c53ac1236dc634e6c4f22cc1.jpg";
import { Link } from "react-router-dom";

export default function Consumers() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <TopImage
        image={img37550ba6c53ac1236dc634e6c4f22cc1}
        title={"Потребителям сетевой организации"}
      />
      <div className="page-grid__content" id="content">
        <div class="text-area">
          <p>
            <b>Уважаемые клиенты!</b>
          </p>
          <p>
            Специально для вашего удобства в АО "Мособлэнерго" работают кураторы
            по каждому из районов обслуживания нашей компании.&nbsp;Если вы
            хотите максимально быстро и удобно получить ответы на все вопросы,
            касающиеся технологического присоединения к электрическим сетям АО
            "Мособлэнерго", обращайтесь по единому многоканальному
            телефону:&nbsp;<b>8 (495) 785-00-00</b>
          </p>
          <p>
            <b>
              Почтовый адрес сетевой организации:{" "}
              <a
                href="mailto:mail@mosoblenergo.ru"
                rel="noopener noreferrer"
                target="_blank"
              >
                mail@mosoblenergo.ru
              </a>{" "}
            </b>
          </p>
          <p>
            <b>
              Новый адрес центра обслуживания клиентов АО «Мособлэнерго»:&nbsp;
            </b>
          </p>
          <p>
            Московская область, Красногорский р-н, 26 км автодороги «Балтия»,
            Бизнес Центр «RigaLand», строение 6, подъезд 4
          </p>
          <p>Координаты для навигатора: 55.785926, 37.241408</p>
          <p>
            <b>Режим работы центра обслуживания клиентов:&nbsp;</b>
          </p>
          <p>
            По рабочим дням – с 8.00 до 17.00, в пятницу с 8.00 до 15.45 (без
            обеденного перерыва)
          </p>
          <p>
            <b>Уважаемые клиенты!</b>
          </p>
          <p>
            Для обеспечения комфортных условий обслуживания в Центральном
            клиентском офисе АО "Мособлэнерго" работает система "электронной
            очереди". Кроме того, вы можете записаться на прием и подачу
            документов, воспользовавшись соответствующей услугой в Личном
            кабинете.
          </p>
          <p>
            Обращаем ваше внимание, что во многих муниципальных образованиях
            открыты Центры обслуживания клиентов (ЦОК), территории обслуживания
            и часы работы которых вы можете узнать&nbsp;
            <Link to="/filials">здесь</Link>.
          </p>
        </div>{" "}
      </div>
    </motion.div>
  );
}
