import React from "react";
import { motion } from "framer-motion";
import TopImage from "../components/TopImage";
import img4c2c362e8d8fa557788c556795d32fae from "../img/photo_5343684875758200971_y.jpg";

export default function InformationOnBonds() {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <TopImage
                image={img4c2c362e8d8fa557788c556795d32fae}
                title={"Информация по облигациям"}
            />
            <div className="page-grid__content" id="content">
                <div className="text-area border-bottom" style={{ margin: "0 auto", maxWidth: 1600 }}>
                    <h2>АО «Мособлэнерго» успешно дебютировало на рынке облигаций</h2>
                    <p>20 августа 2024 г.</p>
                    <p>АО «Мособлэнерго» успешно разместило дебютный выпуск облигаций серии
                        001Р-01 объемом 3,5 млрд рублей с купоном, привязанным к значению
                        Ключевой ставки Банка России. Срок обращения составит 5 лет, по выпуску
                        предусмотрена оферта через 3 года. Облигации включены во второй уровень
                        котировального списка Московской биржи.</p>
                    <p>Дебют компании на рынках капитала вызвал высокий интерес у участников
                        рынка. Компания провела ознакомительный вебинар для инвесторов на
                        площадке Московской биржи, на котором присутствовали свыше 70
                        профессионалов. Уже на этапе премаркетинга первоначальный ориентир по
                        ставке купона был понижен на фоне высокого спроса со стороны инвесторов.
                        В день сбора книги заявок на приобретение облигаций ориентир был снижен
                        еще дважды и зафиксирован на уровне «Ключевая ставка Банка России +
                        1,4%».</p>
                    <p>Общий объем заявок инвесторов почти в 3 раза превысил объём выпуска. В
                        размещении приняли участие все категории институциональных инвесторов:
                        управляющие, инвестиционные и страховые компании, пенсионные фонды и
                        банки. Повышенный интерес к выпуску проявили и частные инвесторы: объем
                        их спроса превысил 1 млрд руб.</p>
                    <p>«Дебютным выпуском облигаций компания начала свою историю на рынках
                        капитала и, на наш взгляд, сделала это крайне успешно. Доверие наших
                        инвесторов и их высокий интерес к выпуску позволили достичь ставки купона
                        на уровне крупнейших российских компаний», – отметил заместитель
                        генерального директора по экономике и финансам Виктор Мельничук.</p>
                    <p>Организаторами выступили Газпромбанк и Банк ДОМ.РФ.</p>
                </div>
            </div>
        </motion.div>
    );
}
