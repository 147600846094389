import React from "react";

export default function TCInformationAboutPersons() {
  return (
    <div className="page-grid__content">
      <div className="text-area">
        <p>Заявок в АО "Мособлэнерго" на перераспределение мощности не поступало.</p>
      </div>
    </div>
  );
}
